<template>
  <div class="bodys">
    <div class="cont">
      <div class="cont-right">
        <div class="cont-center">
          <div
            class="stat_list"
            style="overflow: auto"
            v-infinite-scroll="load"
            :infinite-scroll-immediate="false"
          >
            <div
              class="list"
              style="cursor: pointer"
              v-for="(item, index) in record"
              :key="index"
              @click="recodes(item.id)"
            >
              <div class="list_name">
                <div class="name_1">{{ item.title }}</div>
                <div class="name_2">{{ item.ext_title }}</div>
              </div>
              <div class="time">
                {{ item.notice_type }}:
                <span>{{
                  (item.create_at * 1000) | format("YYYY-MM-DD HH:mm")
                }}</span>
              </div>
            </div>
          </div>
          <!-- <el-empty description="暂无数据"></el-empty> -->
          <div class="stat_xiang">
            <div class="list_name">
              <div class="name_1">{{ info.title }}</div>
              <div class="name_2">{{ info.ext_title }}</div>
            </div>
            <div class="time">
              {{ (info.create_at * 1000) | format("YYYY-MM-DD HH:mm") }}
            </div>
            <div class="texts" v-html="info.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNotices, getNoticeInfo } from "@network/mine";
import { BASE_URL } from "../../../../constants/constants";
export default {
  name: "Station",
  data() {
    return {
      page: 1,
      limit: 7,
      count: 0,
      record: [],
      info: {},
      id: "",
    };
  },
  created() {
  },
  components: {},
  methods: {
    recodes(id) {
      this.id = id;
      this.getNoticeInfo();
    },

    load() {
      this.getNotices();
      
    },
    getNotices() {
      getNotices(this.page, this.limit).then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({ message: "请重新登录", offset: 300 });
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({
            message: "获取记录失败",
            offset: 300,
          });
          return false;
        } else {
          this.count = res.count;
          console.log( res.data[0].id);
          res.data.forEach((item) => {
            if (item.notice_id == 1) {
              item.notice_type = "到期时间";
            } else if (item.notice_id == 2) {
              item.notice_type = "定制时间";
            } else if (item.notice_id == 3) {
              item.notice_type = "取款时间";
            } else if (item.notice_id == 4) {
              item.notice_type = "认证时间";
            }
          });
          if (this.page == 1) {
            this.id = res.data[0].id;
            this.getNoticeInfo();
          }

          this.record.push(...res.data);
          this.page++;
        }
      });
    },
    getNoticeInfo() {
      getNoticeInfo(this.id).then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message.error({
            message: "获取记录失败",
            offset: 300,
          });
          return false;
        } else {
          if (res.data.content.indexOf("img") !== -1) {
            var b = /<img [^>]*src=['"]([^'"]+)[^>]*>/g; // img 标签取src里面内容的正则
            var s = res.data.content.match(b); // 取到所有img标签 放到数组 s里面
            for (var i = 0; i < s.length; i++) {
              var srcImg = s[i].replace(b, "$1"); //取src面的内容
              if (
                srcImg.slice(0, 4) == "http" ||
                srcImg.slice(0, 5) == "https"
              ) {
                //若src前4位置或者前5位是http、https则不做任何修改
                //console.log("不做任何修改");
              } else {
                //修改富文本字符串内容 img标签src 相对路径改为绝对路径
                res.data.content = res.data.content.replace(
                  new RegExp(srcImg, "g"),
                  BASE_URL + srcImg
                );
              }
            }
          }
          this.info = res.data;
        }
      });
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../../../assets/css/vipCenter/ChildComps/station.less";
</style>
